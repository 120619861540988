<template>
    <section class="gardenManagementPage">
        <!-- <g-breadcrumb bread="会员管理" /> -->
        <div class="dis-flex flex-x-start flex-y-start">
            <div class="dis-flex flex-x-start flex-y-start">
                <el-date-picker v-model.trim="searchForm.dayTime" type="date" placeholder="选择筛选日期" value-format="yyyy-MM-dd"
                    @change="selectTime">
                </el-date-picker>
            </div>
            <el-button type="success" @click="add" class="turnBule">添加菜品</el-button>
        </div>


        <TableContainer title="">

            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="type" label="菜品类型" min-width="60" />
                <!-- <el-table-column prop="dishName" label="菜品名称" min-width="60" />
                <el-table-column prop="price" label="菜品价格" min-width="60" />
                <el-table-column prop="dishUnit" label="菜品规格" min-width="60" /> -->
                <el-table-column prop="dayTime" label="菜品日期" min-width="60" />
                <el-table-column width="500" label="菜品操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="textOrange" @click="detailsClick(scope.row)">详情</el-button>
                        <el-button type="text" class="textOrange" @click="editorClick(scope.row)" v-show="scope.row.endDay!=='0'">编辑</el-button>
                        <!-- <el-button type="text" class="textOrange" @click="delteFormClick(scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryDcMeals" />
        </TableContainer>

        <GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm()" @btnClose="resetThemeForm()" width="80%">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="早餐" name="早餐">
                    <el-button type="success" @click="addMorning" class="turnBule m-bom30"
                        v-show="!readonly">新增早餐</el-button>
                    <div class="dis-flex flex-x-start flex-y-start width100 dis-wrap">
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);"
                            v-for="(mornItem, mornIndex) in morningList" :key="mornIndex">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="morningList[mornIndex].dayTime" type="date"
                                    placeholder="请选择菜品日期" value-format="yyyy-MM-dd"
                                    @change="(val) => morningSelectTime(val, mornIndex)" :readonly="readonly" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item :label="'菜品名称' + (mornIndex + 1)">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="morningList[mornIndex].dishName" placeholder="请添加菜品"
                                        @change="(val) => morningChangeType(val, mornIndex)" filterable  reserve-keyword
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="morningList[mornIndex].dishPic !== ''">
                                <img :src="morningList[mornIndex].dishPic"
                                    style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="morningList[mornIndex].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="morningList[mornIndex].price" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            
                            <el-form-item :label="'删除菜品' + (mornIndex + 1)" @click.native="deleteClick(mornIndex, '早餐')"
                                class="dis-flex flex-x-center flex-y-center" v-show="!readonly">
                                <!-- style="background-color: rgba(56, 185, 190, 0.3);" -->
                                <i class="el-icon-delete"></i>
                            </el-form-item>


                        </el-form>

                    </div>
                    <div class="t-c" v-show="morningList.length == 0 && readonly">无早餐数据~</div>
                </el-tab-pane>
                <el-tab-pane label="中餐" name="中餐">
                    <div class="dis-flex flex-x-between flex-y-start width100">
                        <!-- 菜品1 -->
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="noonList[0].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => dishSelectTime(val, 0)"
                                    :readonly="readonly" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称1">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="noonList[0].dishName" placeholder="请添加菜品"
                                        @change="(val) => dishChangeType(val, 0)" filterable reserve-keyword
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="noonList[0].dishPic !== ''">
                                <img :src="noonList[0].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="noonList[0].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="noonList[0].price" maxlength="35" placeholder="请输入描述" :readonly="true" />
                            </el-form-item>
                         
                        </el-form>
                        <!-- 菜品2 -->
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="noonList[1].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => dishSelectTime(val, 1)"
                                    :readonly="readonly" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称2">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="noonList[1].dishName" placeholder="请添加菜品"
                                        @change="(val) => dishChangeType(val, 1)" filterable reserve-keyword
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="noonList[1].dishPic !== ''">
                                <img :src="noonList[1].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="noonList[1].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="noonList[1].price" maxlength="35" placeholder="请输入描述" :readonly="true" />
                            </el-form-item>
                           
                        </el-form>
                        <!-- 菜品3 -->
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="noonList[2].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => dishSelectTime(val, 2)"
                                    :readonly="readonly" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称3">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="noonList[2].dishName" placeholder="请添加菜品"
                                        @change="(val) => dishChangeType(val, 2)" filterable reserve-keyword
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="noonList[2].dishPic !== ''">
                                <img :src="noonList[2].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="noonList[2].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="noonList[2].price" maxlength="35" placeholder="请输入描述" :readonly="true" />
                            </el-form-item>
                            
                        </el-form>
                        <!-- 菜品4 -->
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="noonList[3].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => dishSelectTime(val, 3)"
                                    :readonly="readonly" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称4">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="noonList[3].dishName" placeholder="请添加菜品"
                                        @change="(val) => dishChangeType(val, 3)" filterable reserve-keyword
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="noonList[3].dishPic !== ''">
                                <img :src="noonList[3].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="noonList[3].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="noonList[3].price" maxlength="35" placeholder="请输入描述" :readonly="true" />
                            </el-form-item>
                            
                        </el-form>
                        <!-- 菜品5 -->
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="noonList[4].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => dishSelectTime(val, 4)"
                                    :readonly="readonly" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称5">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="noonList[4].dishName" placeholder="请添加菜品"
                                        @change="(val) => dishChangeType(val, 4)" filterable reserve-keyword
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="noonList[4].dishPic !== ''">
                                <img :src="noonList[4].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="noonList[4].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="noonList[4].price" maxlength="35" placeholder="请输入描述" :readonly="true" />
                            </el-form-item>
                           
                        </el-form>
                    </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="晚餐" name="晚餐">

                    <div class="dis-flex flex-x-between flex-y-start width100">
                     
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="nightList[0].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => nightSelectTime(val, 0)" :readonly="readonly">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称1">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="nightList[0].dishName" placeholder="请添加菜品"
                                        @change="(val) => nightChangeType(val, 0)" filterable allow-create
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="nightList[0].dishPic !== ''">
                                <img :src="nightList[0].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="nightList[0].price" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="nightList[0].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                        </el-form>

                       
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="nightList[1].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => nightSelectTime(val, 1)" :readonly="readonly">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称2">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="nightList[1].dishName" placeholder="请添加菜品"
                                        @change="(val) => nightChangeType(val, 1)" filterable allow-create
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="nightList[1].dishPic !== ''">
                                <img :src="nightList[1].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="nightList[1].price" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="nightList[1].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                        </el-form>

                       
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="nightList[2].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => nightSelectTime(val, 2)" :readonly="readonly">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称3">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="nightList[2].dishName" placeholder="请添加菜品"
                                        @change="(val) => nightChangeType(val, 2)" filterable allow-create
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="nightList[2].dishPic !== ''">
                                <img :src="nightList[2].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="nightList[2].price" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="nightList[2].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                        </el-form>

                      
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="nightList[3].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => nightSelectTime(val, 3)" :readonly="readonly">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称4">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="nightList[3].dishName" placeholder="请添加菜品"
                                        @change="(val) => nightChangeType(val, 3)" filterable allow-create
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="nightList[3].dishPic !== ''">
                                <img :src="nightList[3].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="nightList[3].price" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="nightList[3].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                        </el-form>

                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="nightList[4].dayTime" type="date" placeholder="请选择菜品日期"
                                    value-format="yyyy-MM-dd" @change="(val) => nightSelectTime(val, 4)" :readonly="readonly">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="菜品名称5">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="nightList[4].dishName" placeholder="请添加菜品"
                                        @change="(val) => nightChangeType(val, 4)" filterable allow-create
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="nightList[4].dishPic !== ''">
                                <img :src="nightList[4].dishPic" style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="nightList[4].price" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="nightList[4].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                        </el-form>

                    </div>
                </el-tab-pane> -->
                <el-tab-pane label="晚餐" name="晚餐">
                    <el-button type="success" @click="addNight" class="turnBule m-bom30" v-show="!readonly">新增晚餐</el-button>
                    <div class="dis-flex flex-x-start flex-y-start width100 dis-wrap">
                        <el-form ref="ref_themeForm" label-width="90px" @submit.native.prevent
                            style="width:19%;overflow: hidden;background-color:rgba(255, 255, 255, 0.3);"
                            v-for="(nightItem, nightIndex) in nightList" :key="nightIndex">
                            <el-form-item label="菜品日期">
                                <el-date-picker v-model.trim="nightList[nightIndex].dayTime" type="date"
                                    placeholder="请选择菜品日期" value-format="yyyy-MM-dd"
                                    @change="(val) => nightSelectTime(val, nightIndex)" :readonly="readonly" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item :label="'菜品名称' + (nightIndex + 1)">
                                <el-form ref="ref_searchForm" :inline="true" label-width="90px">
                                    <el-select v-model="nightList[nightIndex].dishName" placeholder="请添加菜品"
                                        @change="(val) => nightChangeType(val, nightIndex)" filterable reserve-keyword
                                        default-first-option :disabled="readonly">
                                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.dishName"
                                            :value="item.id" />
                                    </el-select>
                                </el-form>
                            </el-form-item>
                            <el-form-item label="菜品图片" v-show="nightList[nightIndex].dishPic !== ''">
                                <img :src="nightList[nightIndex].dishPic"
                                    style="width:100px;height:100px;border-radius:8px;">
                            </el-form-item>
                            <el-form-item label="菜品规格">
                                <el-input v-model="nightList[nightIndex].dishUnit" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            <el-form-item label="菜品价格">
                                <el-input v-model="nightList[nightIndex].price" maxlength="35" placeholder="请输入描述"
                                    :readonly="true" />
                            </el-form-item>
                            
                            <el-form-item :label="'删除菜品' + (nightIndex + 1)" @click.native="deleteClick(nightIndex, '晚餐')"
                                class="dis-flex flex-x-center flex-y-center" v-show="!readonly">
                                <!-- style="background-color: rgba(56, 185, 190, 0.3);" -->
                                <i class="el-icon-delete"></i>
                            </el-form-item>


                        </el-form>

                    </div>
                    <div class="t-c" v-show="nightList.length == 0 && readonly">无晚餐数据~</div>
                </el-tab-pane>
            </el-tabs>

        </GDialog>






    </section>
</template>

<script>
import {
    queryDishes,
    queryDcMeals,
    addBatchDcMeals,
    delDcMeals,
    queryTeamDcMeals,
} from '@api/bx/api_contentManagement';
import uploadPics from '@/components/Upload/uploadPics.vue'
export default {
    components: {
        uploadPics,
    },
    data() {
        return {
            leadInForm: {
                voice: [],
                // checkedWords: [1, 2, 3]
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10,
                type: '',
                dayTime: ''
            },
            total: 0,
            tableData: [],
            typeList: [],
            isEditor: false,
            editorRow: {},
            activeName: '中餐',
            dishName: '',
            morningList: [],
            noonList: [
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
                {
                    dishName: '',
                    dayTime: '',
                    type: '中餐',
                    price: '',
                    dishPic: '',
                    dishUnit: ''
                },
            ],
            nightList: [],
            readonly: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < new Date().getTime() - 3600 * 1000 * 24
                },
            },

        }
    },
    created() {
        // this.queryDishes()
        this.queryDcMeals()

    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },
        // tab切换
        handleClick(tab, event) {
            this.queryDishes()
        },
        detailsClick(row) {
            this.clearData()
            this.readonly = true
           
            queryTeamDcMeals({
                dayTime: row.dayTime
            }).then(res => {
                // console.log(res)
                this.morningList = res.dcMealsList == null ? this.morningList : res.dcMealsList
                this.noonList = res.zcDcMealsList == null ? this.noonList : res.zcDcMealsList
                this.nightList = res.wcDcMealsList == null ? this.nightList : res.wcDcMealsList
            })
            this.themeDialog.visible = true
        },
        //删除
        deleteClick(index, type) {
            // console.log(111111)
            if (type == '早餐') {
                this.morningList.splice(index, 1)
            } else {
                this.nightList.splice(index, 1)
            }

        },
        //清空
        clearData() {
            this.activeName = '中餐'
            this.morningList = []
            this.nightList = []
            this.noonList.map((item, index) => {
                Object.keys(item).forEach((key) => {
                    if (item[key] == '中餐') {
                        item[key] = '中餐'
                    } else {
                        item[key] = ""
                    }

                });
            });
            // this.nightList.map((item, index) => {
            //     Object.keys(item).forEach((key) => {
            //         if (item[key] == '晚餐') {
            //             item[key] = '晚餐'
            //         } else {
            //             item[key] = ""
            //         }

            //     });
            // });
            this.queryDishes()
        },
        // 添加菜品
        add() {
            this.clearData()
            this.activeName = '中餐'
            this.queryDishes()
            this.readonly = false
            this.themeDialog.visible = true

        },
        //列表时间筛选
        selectTime(val) {
            this.searchForm.dayTime = val
            this.queryDcMeals()
        },
        //添加早餐
        addMorning() {
            let time = ''
            this.morningList.map((item, index) => {
                if (item.dayTime !== '') {
                    time = item.dayTime
                }
            });
            this.morningList.push({
                dishName: '',
                dayTime: time,
                type: '',
                price: '',
                dishPic: '',
                dishUnit: ''
            })
        },

        //早餐时间筛选
        morningSelectTime(val, id) {
            this.morningList.map((item, index) => {
                item.dayTime = val
            });
        },

        //早餐菜品名称下拉选择
        morningChangeType(val, index) {
            if (this.morningList[index].dayTime == '') {
                this.$alert('请选择菜品日期', '提示', {
                    dangerouslyUseHTMLString: true
                });
                this.morningList[index].dishName = ''
                return
            }
            this.typeList.map((item, indexs) => {
                if (val == item.id) {
                    this.morningList[index].dishName = item.dishName
                    this.morningList[index].type = item.type
                    this.morningList[index].price = item.price
                    this.morningList[index].dishPic = item.dishPic
                    this.morningList[index].dishUnit = item.dishUnit
                }
            });
        },
        
        //添加晚餐
        addNight() {
            let time = ''
            this.nightList.map((item, index) => {
                if (item.dayTime !== '') {
                    time = item.dayTime
                }
            });
            this.nightList.push({
                dishName: '',
                dayTime: time,
                type: '',
                price: '',
                dishPic: '',
                dishUnit: ''
            })
        },
        //晚餐时间筛选
        nightSelectTime(val, id) {
            this.nightList.map((item, index) => {
                item.dayTime = val
            });
        },
        //晚餐菜品名称下拉选择
        nightChangeType(val, index) {
            if (this.nightList[index].dayTime == '') {
                this.$alert('请选择菜品日期', '提示', {
                    dangerouslyUseHTMLString: true
                });
                this.nightList[index].dishName = ''
                return
            }
            this.typeList.map((item, indexs) => {
                if (val == item.id) {
                    this.nightList[index].dishName = item.dishName
                    this.nightList[index].type = '晚餐'
                    this.nightList[index].price = item.price
                    this.nightList[index].dishPic = item.dishPic
                    this.nightList[index].dishUnit = item.dishUnit
                }
            });
        },

        //中餐时间筛选
        dishSelectTime(val, id) {
            this.noonList.map((item, index) => {
                item.dayTime = val
            });
        },

        //中餐菜品名称下拉选择
        dishChangeType(val, index) {
            console.log(val)
            console.log(this.typeList)
            // id 0 1 2 3 4  对应5个菜品
            if (this.noonList[index].dayTime == '') {
                this.$alert('请选择菜品日期', '提示', {
                    dangerouslyUseHTMLString: true
                });
                this.noonList[index].dishName = ''
                return
            }

            this.typeList.map((item, indexs) => {
                if (val == item.id) {
                    // console.log(val==item.id)
                    // console.log(this.noonList[index])
                    this.noonList[index].dishName = item.dishName
                    this.noonList[index].price = item.price
                    this.noonList[index].dishPic = item.dishPic
                    this.noonList[index].dishUnit = item.dishUnit
                }
            });
        },

        // 初始化列表
        queryDcMeals() {
            queryDcMeals(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total
            })
        },

        //菜品列表
        queryDishes() {
            queryDishes({ type: this.activeName }).then(res => {
                this.typeList = res

            })
        },
    

        
        //解绑
        dissolutionFormClick(row) {
            // this.$confirm('是否脱离关系?', '提示', {
            //     confirmButtonText: '脱离关系',
            //     cancelButtonText: '关闭',
            //     type: 'warning'
            // }).then(() => {
            //     bxUserUnbinding({ userId: row.userId }).then(res => {
            //         this.$message.success('解绑成功')
            //         this.bxUser()
            //     })
            // })
        },
        
        //删除
        delteFormClick(row) {
            this.$confirm('永久删除' + '这条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delDcMeals({ dayTime: row.dayTime }).then(res => {
                    this.queryDcMeals()
                })
            })

        },

        //编辑 需后端提供接口
        editorClick(row) {
            this.clearData()
            if(row.endDay=='0'){
                this.readonly = true
            }else{
                this.readonly = false
            }
           
           

            queryTeamDcMeals({
                dayTime: row.dayTime
            }).then(res => {
                // console.log(res)
                this.morningList = res.dcMealsList == null ? this.morningList : res.dcMealsList
                this.noonList = res.zcDcMealsList == null ? this.noonList : res.zcDcMealsList
                this.nightList = res.wcDcMealsList == null ? this.nightList : res.wcDcMealsList
            })
            this.themeDialog.visible = true

        },
        //确定按钮
        submitThemeForm() {
            let noonFlag = false
            noonFlag = this.noonList.every((item, index) => {
                if (item.dishName == '') {
                    this.$alert('中餐菜品名称' + (index + 1) + '不能为空', '提示', {    
                        dangerouslyUseHTMLString: true
                    });
                    return false
                } else {
                    return true
                }
            });

            if (noonFlag) {
                if (this.nightList.length == 0) {
                    this.$alert('请新增晚餐菜品', '提示', {
                        dangerouslyUseHTMLString: true
                    })
                    return
                }

                let nightFlag = false
                nightFlag = this.nightList.every((item, index) => {
                    if (item.dishName == '') {
                        this.$alert('晚餐菜品名称' + (index + 1) + '不能为空', '提示', {
                            dangerouslyUseHTMLString: true
                        });
                        return false
                    } else {
                        return true
                    }
                });


                if (nightFlag) {
                    let dcMealsLists = []
                    if (this.morningList.length !== 0) {
                        let morningFlag = false
                        morningFlag = this.morningList.every((item, index) => {
                            if (item.dishName == '') {
                                this.$alert('早餐菜品名称' + (index + 1) + '不能为空', '提示', {
                                    dangerouslyUseHTMLString: true
                                });
                                return false
                            } else {
                                return true
                            }
                        });
                        if (morningFlag) {
                            this.morningList.map((item, index) => {
                                dcMealsLists.push(item)
                            });

                        }
                    }
                   
                 
                    this.noonList.map((item, index) => {
                        dcMealsLists.push(item)
                    });
                    this.nightList.map((item, index) => {
                        dcMealsLists.push(item)
                    });
                    // console.log(this.noonList)
                    // console.log(this.nightList)
                    // console.log(dcMealsLists)
                    // return
                    addBatchDcMeals({ dcMealsList: dcMealsLists }).then(res => {
                        this.themeDialog.visible = false
                        this.queryDcMeals()
                    })

                    // console.log(dcMealsList)
                    // console.log(this.nightList)
                }
            }

        },
        //取消按钮
        resetThemeForm() {
            this.themeDialog.visible = false
        }


    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

